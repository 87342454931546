import React from "react";
import styles from "./TextBlock.module.scss";
import Layout from "../../layouts/Layout/Layout";
import THEME from "../../../state/theme";
import { Button } from "../../elements";
import { useCustomState } from "../../../state/state";

export default () => {
  const [state, actions] = useCustomState();
  return (
    <Layout col="2" padding>
      <article className={styles.wrapper}>
        <div className={styles.info}>
          <span className={styles.subtitle} style={{ color: THEME.color }}>
            Technical Consulting Solutions Inc
          </span>
          <h2>We create opportunity for new markets &amp; industries</h2>
          <p className={styles.paragraph}>
            <b>TCS </b>is a technically oriented management consulting firm
            providing strategic solutions to complex problems having direct 
            implications on the bottom line.
          </p>
          <p className={styles.paragraph}>
          Our mission is to support each client in becoming the preeminent leader 
          in their respective business.  Our goal is to provide strategic insight 
          and direction that will manage risk and enhance growth and profitability.  
          In keeping with this philosophy, we are committed to unparalleled services 
          on a proprietary basis to each and every client.
          </p>
          <p className={styles.paragraph}>
          As specialists dealing with strategic issues, we bring together a unique group 
          of consultants capable of addressing all the technical, marketing, and financial 
          aspects involved in business planning and process development.  Each consultant is a 
          seasoned industry professional in one of our three areas of expertise, <b>automotive, energy and petroleum. </b> 
          Experienced consultants solving complex business problems has been our hallmark since our firm's inception in 2003.
          </p>
          <p className={styles.paragraph}>
            <b>
            The true worth of a consultant is measured in results.  Here, TCS’s clients value our objectivity, knowledge, 
            specificity, and creativity.  As established members of the business community, we can provide industry insights
            and direction that might not otherwise be available. 
            </b>
          </p>
          <div className={styles.btn_box}>
            <Button to="/services/0" hoverType="solid-gray-tb">
              Read more
            </Button>
            <Button
              to="/services/0"
              type="solid-gray-tb"
              hoverType="solid-color-tb"
              after="&#xf105;"
            />
          </div>
        </div>
      </article>
      <article className={styles.wrapper}>
        <figure className={styles.image}>
          <div
            className={styles.rectangle}
            style={{ borderColor: THEME.color }}
          />
          <div className={styles.photo}>
            <span
              style={{
                background:
                  "url(" +
                  state.data.header_bgs.about_video_poster +
                  ") center/cover",
              }}
            />
          </div>
        </figure>
      </article>
    </Layout>
  );
};
