import React, { Fragment } from "react";
import Layout from "../../layouts/Layout/Layout";
import styles from "./Petroleum.module.scss";
import {
  Header,
  
} from "../../widgets";
import { useCustomState } from "../../../state/state";

export default () => {
  const state = useCustomState()[0];

  return (
    <Fragment>
      <Header img={state.data.header_bgs.petroleum}>Petroleum</Header>
      <Layout col="2" padding>
        <div>
          <figure
            className={styles.photo}
            style={{ background: "url(" + state.data.header_bgs.petroleum+ ") center/cover" }}
          />
        </div>
        <div className={styles.info}>
        <h1>Petroleum</h1>
        <p>
            Across the world, oil, gas and petrochemical companies are looking for innovative
            solutions to help them address the current challenges of a global economic downturn,
            decline in overall margins, and increased emphasis in process safety compliance. TCS provides
            unique answers to these challenges with our expert consulting solutions across the oil,
            gas and petrochemical industry.
        </p>
        <p>
            TCS services can be offered industry-wide from the integrated supermajors to the regional
            independents to the national oil companies. Our consultants have developed industry-leading
            methodologies, best practices, and robust sofware solutions that reflect our industry
            insights and vast experience in our core competencies.
        </p>
        <ul>
            <li>Upstream Consulting Solutions.</li>
            <li>Feasibility studies.</li>
            <li>Field development planning.</li>
            <li>Concept selection and design</li>
            <li>Front-end engineering and design.</li>
            <li>Process Safety Management Solutions.</li>
            <li>Process Safety Management program, audits, training and implementation.</li>
            <li>Process safety information management.</li>
            <li>Pressure relief design analysis and training.</li>
            <li>Quantitative risk analysis on flare systems.</li>
            <li>Management of change implementation.</li>
            <li>Mechanical integrity program development.</li>
            <li>Risk-based inspection program development.</li>
            <li>Inspection program management.</li>
            <li>Process hazards analysis facilitation.</li>
            <li>National Emphasis Program (NEP) audits and training.</li>
        </ul>
        </div>
      </Layout>
    </Fragment>
  );
};
