import React from "react";
import { useCustomState } from "../../../state/state";
import styles from "./ParallaxHeader.module.scss";
import { Layout } from "../../layouts";
import { InputDiscount } from "../../ui";
import { Button } from "../../elements";

export default () => {
  const [state, actions] = useCustomState();

  return (
    <div
      className={styles.parallax}
      style={{
        backgroundImage: "url(" + state.data.header_bgs.about + ")",
      }}
    >
      <Layout col="1">
        <div className={styles.intro}>
          <p>
            We have <b>over 20 years</b> of industry experience.
          </p>
          <h1>
            Technical{" "}
            <b>
              Consulting
              <br /> Solutions
            </b>{" "}
            Inc
          </h1>
          
        </div>
      </Layout>
      <div className={styles.form}>
        <Layout col="1" padding style={{ background: "rgb(240,240,240)" }}>
          <InputDiscount />
        </Layout>
      </div>
    </div>
  );
};
