import Mock from "./mock";

const photo = require("../assets/placeholders/photo.jpg");
const photo_video = require("../assets/placeholders/photo_video.jpg");
const photo_automotive = require("../assets/placeholders/photo_automotive.jpg");
const photo_alternative_energy= require("../assets/placeholders/photo_alternative_energy.jpg");
const photo_petroleum = require("../assets/placeholders/photo_petroleum.jpg");
const photo_building = require("../assets/placeholders/photo_building.jpg");
const icon = require("../assets/placeholders/icon.svg");
const logo_tcs=require("../assets/placeholders/tcs-logo-white.svg");


const database = {
 
  fast_links: [
    {
      title: "Homepage",
      url: "/home",
    },
    {
      title: "Alternative Energy",
      url: "/alternative-energy",
    },
    {
      title: "Automotive",
      url: "/automotive",
    },
    {
      title: "Petroleum",
      url: "/petroleum",
    },
    {
      title: "Contact us",
      url: "/contacts",
    },
  ],

  logo_bgs:{ logo: logo_tcs },
  
  header_bgs: {
    about: photo_building,
    about_video_poster: photo_video,
    blog: photo,
    contacts: photo,
    contacts_w: photo,
    portfolio: photo,
    services: photo,
    team: photo,
    team_w: photo,
    facts: photo,
    subscribe: photo,
    list: photo,
    automotive: photo_automotive,
    alternative: photo_alternative_energy,
    petroleum: photo_petroleum,
    
  },
  menu: [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "Alternative Energy",
      url: "/alternative-energy",
    },
    {
      name: "Automotive",
      url: "/automotive",
    },
    {
      name: "Petroleum",
      url: "/petroleum",
    },
    {
      name: "Contacts",
      url: "/contacts",
    },
  ],
  
  services: [
    {
      p1:" Technical Consulting Solutions (TCS)  provides high quality energy engineering and management consulting services to enable rapid deployment of efficient, cost-effective, reliable, and environment-friendly energy systems.  Our customized technical solutions and services are provided to utility companies, government agencies, and corporations that seek to improve energy production, delivery, and end-use.",
      p2:" TCS’s staff has a broad-based technical proficiency in state-of-the-art energy systems. Our team works closely with clients to apply this expertise in the following service areas: ",
      p3:" By assessing a client’s current energy usage to determine the performance of existing infrastructure and current energy savings methods to develop a comprehensive plan for energy reduction. We can develop solutions for lowering the cost to implement new energy technologies through local energy incentive programs and evaluate options for generating client controlled electrical power with renewable or co-generation systems.",
      p4:" Distributed generation, the application of relatively small power plants near or at load centers, is generating competition among energy suppliers and regulated power delivery service providers. Advancements in conventional small generators (i.e. reciprocating gensets, gas turbines) and new technologies (i.e. micro-turbines and fuel cells) are proving to be more efficient, cleaner, and easier to site than larger centralized power plants.",
      p5:" TCS provides a full complement of technical and management services that help energy investors identify and evaluate energy technology targets of opportunity. Growing energy demand, aging infrastructure, and the availability of new technologies all contribute to a climate of increasing opportunities for the deployment of new power generation, energy distribution, and innovative end-use energy technologies. Energy Technology Research & Development",
      full:
        "Technical Consulting Solutions (TCS)  provides high quality energy engineering and management consulting services to enable rapid deployment of efficient, cost-effective, reliable, and environment-friendly energy systems.  Our customized technical solutions and services are provided to utility companies, government agencies, and corporations that seek to improve energy production, delivery, and end-use.",
      icon: icon,
      id: 0,
      img: photo_alternative_energy,
      steps: [
        {
          text:
            "Competently benchmark multifunctional data with cross-unit               synergy. Synergistically incubate user-centric content rather than               2.0 paradigms. Interactively enable optimal scenarios with effective channels.",
          title: "Construction project planning",
        },
        {
          text:
            "Competently benchmark multifunctional data with cross-unit               synergy. Synergistically incubate user-centric content rather than               2.0 paradigms. Interactively enable optimal scenarios with effective channels.",
          title: "Monitor and coordinate all work",
        },
        {
          text:
            "Competently benchmark multifunctional data with cross-unit               synergy. Synergistically incubate user-centric content rather than               2.0 paradigms. Interactively enable optimal scenarios with effective channels.",
          title: "Post construction phase",
        },
      ],
      subtitle: "High quality energy engineering",
      text:
        "TCS works to develop new and innovative technologies that enhance the ability of our customers to buy and sell energy, monitor supply and demand, and monitor and maintain systems.",
      title: "Alternative-energy",
    },
    {
      p1: "TCS assists automotive manufacturing and service companies develop growth strategies to successfully compete in today’s global marketplace.  Developing strategies to meet today’s complex and fast changing automotive industry, technology and competitive forces is a major challenge for many automotive and auto parts manufacturing companies. Our strategic approach seeks to identify opportunities that integrate your company’s core capabilities with innovation – in parts manufacturing, and services – to achieve profitable growth and gain a competitive advantage. ",
      p2: "TCS has a proven record of helping auto and part manufacturers develop and implement strategies to capitalize on the new business growth opportunities arising from these changes. Our staff is comprised of former senior managers who had to formulate and execute new strategies at companies they managed -- many in turnaround situations. ",
      p3: "We assess future market, competitive, and technology trends, as well as the impact of regulatory and  economic changes on the automotive industry. Using this research, we identify several alternate strategy scenarios. These are reviewed in working sessions with the client’s team and a final strategy is chosen.   TCS can assist its clients by allowing them to focus on the areas of competitive differentiation in the automotive industry while we take on non-core processes. We also drive value in the critical, high-margin area of aftersales and service.",
      p4: "Through an integrated network of automotive outsourcing partners and experts around the world, we offer a comprehensive suite of automotive industry services, including:",
      p5:"Business Process Outsourcing (BPO) Application Outsourcing Business Transformation Services Infrastructure Outsourcing Strategic Delivery Capability Services Automotive legal assistance and expert witnesses Part inventory planning and transparency  Freight cost Reduction ",
      featured: "yes",
      full:
        "Continually incubate standards compliant customer service rather than professional initiatives. Intrinsicly whiteboard web-enabled potentialities via team building supply chains. Collaboratively integrate granular innovation and performance based intellectual capital. Compellingly redefine multimedia based paradigms for high-quality portals. Quickly build out-of-the-box e-tailers for customer directed opportunities.",
      icon: icon,
      id: 1,
      img: photo_automotive,
      steps: [
        {
          text:
            "Competently benchmark multifunctional data with cross-unit               synergy. Synergistically incubate user-centric content rather than               2.0 paradigms. Interactively enable optimal scenarios with effective channels.",
          title: "Construction project planning",
        },
        {
          text:
            "Competently benchmark multifunctional data with cross-unit               synergy. Synergistically incubate user-centric content rather than               2.0 paradigms. Interactively enable optimal scenarios with effective channels.",
          title: "Monitor and coordinate all work",
        },
        {
          text:
            "Competently benchmark multifunctional data with cross-unit               synergy. Synergistically incubate user-centric content rather than               2.0 paradigms. Interactively enable optimal scenarios with effective channels.",
          title: "Post construction phase",
        },
      ],
      subtitle: "Making planning for project",
      text:
        "TCS has a proven record of helping auto and part manufacturers develop and implement strategies to capitalize on the new business growth opportunities arising from this changes.",
      title: "Automotive",
    },
    {
      p1: "Across the world, oil, gas and petrochemical companies are looking for innovative solutions to help them address the current challenges of a global economic downturn, decline in overall margins, and increased emphasis in process safety compliance. TCS provides unique answers to these challenges with our expert consulting solutions across the oil, gas and petrochemical industry. ",
      p2: " TCS services can be offered industry-wide from the integrated supermajors to the regional independents to the national oil companies. Our consultants have developed industry-leading methodologies, best practices, and robust software solutions that reflect our industry insights and vast experience in our core competencies.",
      p3: "Upstream Consulting Solutions  Feasibility studies Field development planning Concept selection and design Front-end engineering and design Process Safety Management Solutions Process safety management program audits, training, and implementation Process safety information management  Pressure relief design analysis and training Quantitative risk analysis on flare systems Management of change implementation Mechanical integrity program development Risk-based inspection program development Inspection program management  Process hazards analysis facilitation National Emphasis Program (NEP) audits and training",
      p4:"",
      p5:"",
      full:
        "Continually incubate standards compliant customer service rather than professional initiatives. Intrinsicly whiteboard web-enabled potentialities via team building supply chains. Collaboratively integrate granular innovation and performance based intellectual capital. Compellingly redefine multimedia based paradigms for high-quality portals. Quickly build out-of-the-box e-tailers for customer directed opportunities.",
      icon: icon,
      id: 2,
      img: photo_petroleum,
      steps: [
        {
          text:
            "Competently benchmark multifunctional data with cross-unit               synergy. Synergistically incubate user-centric content rather than               2.0 paradigms. Interactively enable optimal scenarios with effective channels.",
          title: "Construction project planning",
        },
        {
          text:
            "Competently benchmark multifunctional data with cross-unit               synergy. Synergistically incubate user-centric content rather than               2.0 paradigms. Interactively enable optimal scenarios with effective channels.",
          title: "Monitor and coordinate all work",
        },
        {
          text:
            "Competently benchmark multifunctional data with cross-unit               synergy. Synergistically incubate user-centric content rather than               2.0 paradigms. Interactively enable optimal scenarios with effective channels.",
          title: "Post construction phase",
        },
      ],
      subtitle: "Create value for future product",
      text:
        "Across the world, oil, gas and petrochemical companies are looking for innovative solutions to help them address the current challenges of a global economic downturn.",
      title: "Petroleum",
    },
  ],
  
  video: {
    header: "",
    about: "",
  },
};

Mock.onGet("/api/data").reply((config) => {
  const response = database;
  return [200, response];
});
