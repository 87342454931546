import React, { Fragment } from "react";
import Layout from "../../layouts/Layout/Layout";
import styles from "./Automotive.module.scss";
import {
  Header,
  
} from "../../widgets";
import { useCustomState } from "../../../state/state";

export default () => {
  const state = useCustomState()[0];

  return (
    <Fragment>
      <Header img={state.data.header_bgs.automotive}>Automotive</Header>
      <Layout col="2" padding>
        <div>
          <figure
            className={styles.photo}
            style={{ background: "url(" + state.data.header_bgs.automotive+ ") center/cover" }}
          />
        </div>
        <div className={styles.info}>
        <h1>Automotive</h1>
        <p>
            TCS assists automotive manufacturing and service companies develop growth strategies to
            successfully compete in today's global marketplace. Developing strategies to meet today's complex
            and fast changing automotive and auto parts manufacturing companies. Our strategic approach seeks to
            identify opportunities that integrate your company's core capabilities with innovation - in parts
            manufacturing, and services -  to achieve profitable growth and gain a competitive advantage.
        </p>
        <p>
            TCS has a proven record of helping auto and part manufacturers develop and implement strategies
            to capitalize on the new business growth opportunities arising from these changes. Our staff is 
            comprised of former senior managers who had to formulate and execute new strategies at companies
            they managed -- many in turnaround situations.
        </p>
        <p>
            We assess future market, competitive, and technology trends, as well as the impact of regulatory
            and economic changes on the automotive industry. Using this research, we identify several alternate
            strategy scenarios. These are reviewed in working sessions with
            the client's team and a final strategy is chosen. TCS can assist its clients by allowing them 
            to focus on the areas of competitive differentiation in the automotive industry while we 
            take on non-core processes. We also drive value in the critical, high-margin area of aftersales and services.
        </p>
        <br></br>
        <span>Outsoursing & Infrastructure Improvement Services.</span>
        <p>
            Through an integrated network of automotive outsourcing partners and experts around the world, we offer
            a comprehensive suite of automotive industry services, including:
        </p>
        <ul>
            <li>Business Process Outsourcing (BPO).</li>
            <li>Application Outsourcing.</li>
            <li>Business Transformation Services.</li>
            <li>Infrastructure Outsourcing.</li>
            <li>Strategic Delivery Capability Services.</li>
            <li>Automotive legal assistance and expert witnesses.</li>
            <li>Part inventory planning and transparency.</li>
            <li>Freight cost reduction.</li>
        </ul>
        </div>
      </Layout>
    </Fragment>
  );
};
