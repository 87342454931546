import React from "react";
import { useCustomState } from "../../../state/state";
import styles from './Logo.module.scss';

export default () => {

  const state = useCustomState()[0];

  return(
  <figure>
  
   <img  src={state.data.logo_bgs.logo}>

   </img>
  </figure>
  )};
