import React, { Fragment } from "react";
import Layout from "../../layouts/Layout/Layout";
import styles from "./AlternativeEnergy.module.scss";
import {
  Header,
  
} from "../../widgets";
import { useCustomState } from "../../../state/state";

export default () => {
  const state = useCustomState()[0];

  return (
    <Fragment>
      <Header img={state.data.header_bgs.alternative}>Alternative Energy</Header>
      <Layout col="2" padding>
        <div>
          <figure
            className={styles.photo}
            style={{ background: "url(" + state.data.header_bgs.alternative+ ") center/cover" }}
          />
        </div>
        <div className={styles.info}>
        <h1>Alternative Energy</h1>
        <p>
            Technical Consulting Solutions (TCS) provides high quality energy engineering and 
            management consulting services to enable rapid deployment of efficient, cost-effective,
            reliable, and environment-friendly energy systems. Our customized technical solutions and
            services are provided to utility companies, goverment agencies, and corporation that seek
            to improve energy production, delivery, and end-use.
        </p>
        <p>
            TCS's staff has a broad-based technical proficiency in state-of-the-art energy systems. Our team
            works closely with clients to apply this expertise in the following services areas:
        </p>
        <br></br>
        <span>Energy Efficiency & Demand Reduction</span>
        <p>
            By assessing a client's current energy usage to determine the performance of existing
            infrastructure and current energy savings methods to develop a comprehensive plan for 
            energy reduction. We can develop solutions for lowering the cost to implement new energy
            technologies through local energy incentive programs and evaluate options for generating
            client controlled electrical power with renewable or co-generation systems.
        </p>
        <br></br>
        <span>Distributed Generation</span>
        <p>
            As an alternative energy consulting company, TCS has strong professional interest and experience
            in the evaluation and application of distributed generating technologies for various energy market segments.
        </p>
        <p>
            Distributed generation, the application of relatively small power plants near or at load
            centers, is generating competition among energy suppliers and regulated power delivery
            service providers. Advancements in conventional small generators (i.e. reciprocating
            gencets, gas turbines) and new technologies (i.e. micro-turbines and fuel cells) are proving
            to be more efficient, cleaner and easier to site thant larger centralized power plants.
        </p>
        <p>
            Distributed generation, therefore, has the potential to reduce power delivery costs, open new energy 
            service markets, improve electric service reliability, and decrease environmental impact from power generation.
        </p>
        <br></br>
        <span>Technology & Market Assessments.</span>
        <p>
            TCS provides a full complement of technical and management services that help energy
            investors identify and evaluate energy technology targets of opportunity. Growing energy demand, aging infrastructure, and the
            availability of new technologies all contribute to a climate of increasing opportunities for the deployment of new power generation, energy
            distribution, and innovative end-use energy technologies.
        </p>
        <br></br>
        <span>Energy Technology Research & Development.</span>
        <p>
            TCS works to develop new and innovative technologies that enhance the ability of our customers to buy and sell energy, monitor supply and 
            demand, and monitor and maintain systems.
        </p>
        </div>
      </Layout>
    </Fragment>
  );
};
