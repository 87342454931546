import React, { Fragment } from "react";
import {
  
  Services,
  TextBlock,
  Contacts,
  ParallaxHeader,
} from "../../widgets";
import { useCustomState } from "../../../state/state";

export default () => {
  const state = useCustomState()[0];

  return (
    <Fragment>
          <ParallaxHeader />
          <Services data={state.data.services} />
          <TextBlock data={state.data.services} />
          <Contacts />
    </Fragment>
  );
};
